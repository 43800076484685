import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.svg";

function Header(props) {
  return (
    <header
      className="App-header"
      style={{
        display: "flex",
        alignItems: "center",
        maxWidth: "80%",
        height: "80px",
        margin: "0 auto",
      }}
    >
      <div
        className="dflex"
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Link to="/">
            <img src={logo} width="100px" />
          </Link>
        </div>
        <div className="dflex contact-details">
          {/* <p style={{ color: "#F15D26", fontWeight: "bold" }}>
              For More Details, Contact
            </p> */}
          {/* <a
            style={{ color: "#F15D26", marginLeft: 20, fontWeight: "bold" }}
            href="tel:+919019231923"
          >
            +91-9019231923
          </a>
          <a
            style={{ color: "#F15D26", fontWeight: "bold", marginLeft: 20 }}
            href="mailto:reservation@zionhills.in"
          >
            reservation@zionhills.in
          </a> */}
        </div>
      </div>
    </header>
  );
}

export default Header;
