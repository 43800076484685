import { useContext, useEffect, useState } from "react";
import "./App.css";
import _ from "lodash";
import {
  isWeekend,
  formatISO,
  format,
  parse,
  parseISO,
  addDays,
  differenceInDays,
  set,
  add,
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AvailableList from "./AvailableList";

import logo from "./logo.svg";

import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate } from "react-router-dom";

// const override = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

import { AppContext } from "./App";
import * as Yup from "yup";
import Header from "./Header";

function Dashboard() {
  const { store, setStore } = useContext(AppContext);
  const navigate = useNavigate();
  // console.log(store);
  const [available, setAvailable] = useState({});
  const [priceList, setPriceList] = useState([]);
  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#F15D26");

  let schema = Yup.object().shape({
    checkin: Yup.date()
      .default(() => set(new Date(), { hours: 15, minutes: 0, seconds: 0 }))
      .required(),
    checkout: Yup.date()
      .default(() =>
        set(addDays(new Date(), 1), { hours: 12, minutes: 0, seconds: 0 })
      )
      .required(),
    adults: Yup.number()
      .integer()
      .default(1)
      .min(1)
      .typeError("Adults: Enter a valid number")
      .required(),
    kids: Yup.number()
      .integer()
      .min(0)
      .typeError("Kids: Enter a valid number")
      .required(),
  });

  const handleCheckAvailablity = async () => {
    try {
      const valid = await schema.validate({
        checkin: store.checkin,
        checkout: store.checkout,
        adults: store.adults,
        kids: store.kids,
      });
      setStore({
        ...store,
        errorName: "",
        errors: [],
      });
      return true;
    } catch (err) {
      console.log(err);
      setStore({
        ...store,
        errorName: err.name,
        errors: err.errors,
      });
      return false;
    }
  };

  const fetchAvailableInventory = async () => {
    if (!(await handleCheckAvailablity())) {
      return;
    }
    setLoading(true);
    fetch(
      `https://us-central1-zionhills-f62b5.cloudfunctions.net/app/availablity?checkin=${store?.checkin?.toISOString()}&checkout=${store?.checkout?.toISOString()}`,

      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-API-KEY": "0e6f0324-ac86-4c7f-a2eb-59268ab0907f",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(async (res) => {
      if (res.ok) {
        let { availableVillas } = await res.json();
        let sortedVillas = _.groupBy(availableVillas, function (b) {
          return b.unitType;
        });
        setAvailable(sortedVillas);
        setLoading(false);
      } else {
        setLoading(false);
        setAvailable([]);
      }
    });
  };

  const fetchPriceList = async () => {
    fetch(
      `https://us-central1-zionhills-f62b5.cloudfunctions.net/app/listPrice`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-API-KEY": "0e6f0324-ac86-4c7f-a2eb-59268ab0907f",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(async (res) => {
      if (res.ok) {
        let { data } = await res.json();

        setPriceList(data);
      } else {
        setPriceList([]);
      }
    });
  };

  useEffect(() => {
    fetchAvailableInventory();
    fetchPriceList();
  }, [store.checkin, store.checkout]);

  const handleStartDateChange = (date) => {
    let startDate = set(date, { hours: 15, minutes: 0, seconds: 0 });

    setStore({
      ...store,
      checkin: startDate,
      checkout: set(addDays(startDate, 1), {
        hours: 12,
        minutes: 0,
        seconds: 0,
      }),
      nights: 1,
    });
  };

  const handleEndDateChange = (date) => {
    let endDate = set(date, { hours: 12, minutes: 0, seconds: 0 });

    setStore({
      ...store,
      checkout: endDate,
      nights: differenceInDays(endDate, store.checkin) + 1,
    });
  };
  const handleOnChange = (e) => {
    setStore({
      ...store,

      errorName: "",
      errors: [],

      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setStore({
      ...store,
      bookingId: "",
      isDirty: false,
    });
  }, [loading]);

  return (
    <div className="App">
      <Header />
      {/* <header className="App-header">
        <div
          className="dflex"
          style={{ justifyContent: "center", margin: "20px 0" }}
        >
          <img src={logo} width="150" onClick={() => navigate("/")} />
        </div>
      </header> */}
      <div className="container">
        {store.errorName && store.errors ? (
          <div
            style={{
              backgroundColor: "#FF0033",
              marginTop: 20,
              padding: 10,
            }}
          >
            <div>
              {store.errors.map((d, i) => {
                return (
                  <p key={i} style={{ color: "#fff" }}>
                    {d}
                  </p>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className="datepicker-zion">
          <div
            className="dflex"
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              className="dflex"
              style={{ alignItems: "center", flexWrap: "wrap" }}
            >
              <label className="label">Checkin</label>
              <div style={{ marginLeft: 10 }}>
                <DatePicker
                  selected={store.checkin}
                  minDate={new Date()}
                  onChange={(date) => handleStartDateChange(date)}
                  selectsStart
                  startDate={store.checkin}
                  dateFormat="dd/MM/yyyy"
                  endDate={store.endDate}
                />
              </div>

              <label className="label" style={{ marginLeft: 20 }}>
                Checkout
              </label>
              <div style={{ marginLeft: 10 }}>
                <DatePicker
                  selected={store.checkout}
                  onChange={(date) => handleEndDateChange(date)}
                  selectsEnd
                  startDate={store.checkin}
                  endDate={store.checkout}
                  dateFormat="dd/MM/yyyy"
                  minDate={addDays(store.checkin, 1)}
                />
              </div>
            </div>
            <div
              className="dflex"
              style={{
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: "10px",
              }}
            >
              <label className="label">Adults & Kids (> 10 years)</label>
              <div style={{ marginLeft: 10 }}>
                <input
                  className="home-input"
                  name="adults"
                  onChange={handleOnChange}
                  value={Number(store.adults)}
                  style={{ width: 50 }}
                  type="number"
                />
              </div>
              <label className="label" style={{ marginLeft: 20 }}>
                Kids (5-10 years)
              </label>
              <div style={{ marginLeft: 10 }}>
                <input
                  className="home-input"
                  name="kids"
                  onChange={handleOnChange}
                  value={Number(store.kids)}
                  style={{ width: 50 }}
                  type="number"
                />
              </div>
              <div style={{ marginLeft: 20 }}>
                <button
                  className="primary"
                  onClick={fetchAvailableInventory}
                  style={{ padding: 10 }}
                >
                  Check
                </button>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <p>
              <strong style={{ color: " #F15D26" }}>{store.nights}</strong>{" "}
              Night
            </p>
          </div>
        </div>

        <div className="divider"></div>
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <PulseLoader color={color} loading={loading} size={20} />
          </div>
        ) : (
          <AvailableList
            available={available}
            priceList={priceList}
            startDate={store.checkin}
            endDate={store.checkout}
          />
        )}
      </div>
      <div style={{ margin: "20px auto", textAlign: "center" }}>
        <p className="footer-text" style={{ fontWeight: "bold" }}>
          Not finding what you are looking for? Contact us at{" "}
          <a href="tel:+919019231923">+91-9019231923</a>
        </p>
      </div>
    </div>
  );
}

export default Dashboard;
