import React, { useMemo, useState } from "react";
import Header from "./Header";
// import menu1 from "./menu1.jpeg";
// import menu2 from "./menu2.jpeg";
// import menu3 from "./menu3.jpeg";

function Menu(props) {
  const [menu, setMenu] = useState([]);
  useMemo(() => {
    fetch(
      "https://us-central1-zionhills-f62b5.cloudfunctions.net/app/menu"
      // "http://localhost:5001/zionhills-f62b5/us-central1/app/menu"
    )
      .then((d) => d.json())
      .then((data) => setMenu(data));
  }, []);
  console.log(menu);
  return (
    <div
      style={{
        maxWidth: "80%",
        margin: "0 auto",
      }}
    >
      <Header />
      {menu
        .sort((a, b) => a.order - b.order)
        .map((d) => {
          return (
            <div style={{ width: "100%" }}>
              <img src={d.image} style={{ maxWidth: "100%", width: "100%" }} />
            </div>
          );
        })}
      {/* <div style={{ width: "100%" }}>
        <img src={menu1} style={{ maxWidth: "100%", width: "100%" }} />
      </div>
      <div style={{ width: "100%" }}>
        <img src={menu2} style={{ maxWidth: "100%", width: "100%" }} />
      </div>
      <div style={{ width: "100%" }}>
        <img src={menu3} style={{ maxWidth: "100%", width: "100%" }} />
      </div> */}
    </div>
  );
}

export default Menu;
