import React, { useContext, useEffect } from "react";
import logo from "./logo.svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import BackgroundSlider from "react-background-slider";
import DatePicker from "react-datepicker";
import { addDays, set, differenceInDays } from "date-fns";
import { AppContext } from "./App";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Header from "./Header";
function PrivacyPolicy(props) {
  return (
    <div>
      <Header />
      {/* <div className="divider"></div> */}
      <div
        style={{
          alignItems: "center",
          maxWidth: "80%",
          margin: "20px auto",
          color: "#555!important",
        }}
      >
        {/* <h2 className="heading">Zion Hills Golf County</h2>
        <p>Kolar</p> */}
      </div>

      <div
        style={{
          display: "flex",
          maxWidth: "80%",
          margin: "20px auto",
        }}
      >
        <div
          className="content"
          style={{ width: "65%", paddingRight: "30px", height: "100vh" }}
        >
          <h2 className="heading">Privacy</h2>

          <p style={{ marginTop: "20px" }}>
            We recognize that privacy is important to our guests. We will
            protect your personal data, to keep it secure as per applicable
            privacy and data protection laws.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
