import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import _ from "lodash";
import {
  isWeekend,
  formatISO,
  format,
  parse,
  parseISO,
  addDays,
  differenceInDays,
  set,
  add,
  addMinutes,
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AvailableList from "./AvailableList";

import logo from "./logo.svg";

import { PulseLoader, ClipLoader } from "react-spinners";
import { AppContext } from "./App";
import { useNavigate } from "react-router-dom";

import useRazorpay from "react-razorpay";

import { toast } from "react-toastify";
import Countdown from "react-countdown";
import { useBlocker } from "./router-helper";
import * as Yup from "yup";
import Header from "./Header";

function BookingForm(props) {
  const navigate = useNavigate();
  const Razorpay = useRazorpay();

  const { store, setStore } = useContext(AppContext);
  const [order, setOrder] = useState();
  const [available, setAvailable] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let [color, setColor] = useState("#F15D26");

  const countdownRef = React.useRef(Date.now());

  let schema = Yup.object().shape({
    checkin: Yup.date()
      .default(() => set(new Date(), { hours: 15, minutes: 0, seconds: 0 }))
      .required(),
    checkout: Yup.date()
      .default(() =>
        set(addDays(new Date(), 1), { hours: 12, minutes: 0, seconds: 0 })
      )
      .required(),

    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    phone: Yup.string()
      .length(10, "Phone number has to be 10 digit")
      .required("Phone number is required"),
    adults: Yup.number()
      .integer()
      .default(1)
      .min(1)
      .typeError("Adults: Enter a valid number")
      .required(),
    kids: Yup.number()
      .integer()
      .min(0)
      .typeError("Kids: Enter a valid number")
      .required(),
  });

  const handleCheckAvailablity = async () => {
    try {
      await schema.validate({
        checkin: store.checkin,
        checkout: store.checkout,
        adults: store.adults,
        kids: store.kids,
        name: store.name,
        phone: store.phone,
        email: store.email,
      });
      setStore({
        ...store,
        errorName: "",
        errors: [],
      });
      return true;
    } catch (err) {
      setStore({
        ...store,
        errorName: err.name,
        errors: err.errors,
      });
      return false;
    }
  };

  const createTempBooking = async (availableVilla) => {
    const createdTime = new Date();
    const updatedTime = new Date();

    return await fetch(
      `https://us-central1-zionhills-f62b5.cloudfunctions.net/app/addTempBooking`,
      // `http://localhost:5001/zionhills-f62b5/us-central1/app/addTempBooking`,

      {
        method: "POST",
        body: JSON.stringify({
          name: "Reserved",
          assignedVilla: availableVilla,
          checkin: store.checkin,
          checkout: store.checkout,
          checkedIn: false,
          checkedOut: false,
          cancelled: false,
          notifiedOwner: false,
          createdTime: createdTime,
          updatedTime: updatedTime,
          expiresIn: 900000,
          expiresAt: addMinutes(createdTime, 15),
        }),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-API-KEY": "0e6f0324-ac86-4c7f-a2eb-59268ab0907f",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  };

  const fetchAvailableInventory = async () => {
    setLoading(true);
    return fetch(
      `https://us-central1-zionhills-f62b5.cloudfunctions.net/app/availablity?checkin=${store?.checkin?.toISOString()}&checkout=${store?.checkout?.toISOString()}`,
      // `http://localhost:5001/zionhills-f62b5/us-central1/app/availablity?checkin=${store?.checkin?.toISOString()}&checkout=${store?.checkout?.toISOString()}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-API-KEY": "0e6f0324-ac86-4c7f-a2eb-59268ab0907f",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  };

  const getAvailbiltyCreateTemp = async (store) => {
    const availableInventoryRes = await fetchAvailableInventory();
    if (availableInventoryRes.ok) {
      let { availableVillas } = await availableInventoryRes.json();
      let oneVilla = availableVillas.filter(
        (d) => d.unitType == store.bookingType
      );
      // console.log("available", availableVillas);
      // console.log("oneVilla", oneVilla);
      if (oneVilla.length > 0) {
        // if (!store.bookingId) {
        const bookingIdRes = await createTempBooking(oneVilla[0]?.id);
        if (bookingIdRes.ok) {
          const { bookingId, taskDetails } = await bookingIdRes.json();
          // console.log(bookingId);
          // console.log(taskDetails);
          setStore({
            ...store,
            bookingId,
            taskDetails,
          });
          setLoading(false);
        }
        // } else {
        //   navigate("/");
        // }
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    setStore({
      ...store,
      isDirty: true,
    });
    return () => setStore({ ...store, isDirty: false });
  }, [loading]);

  useEffect(() => {
    //re-check if selected booking type is available
    //block a villa
    setLoading(true);

    if (!store.bookingType) {
      navigate("/");
    }
    if (store.bookingType) {
      toast.promise(getAvailbiltyCreateTemp(store), {
        pending: "Blocking your selection",
        success: "Please complete the payment in 15 mins",
        error: "Error in selection, please retry",
      });
    }
  }, [store.bookingType]);

  const handleChange = (e) => {
    setStore((prev) => ({
      ...prev,
      errors: [],
      errorName: "",
      [e.target.name]: e.target.value,
    }));
  };

  const createOrder = async (store) => {
    return await fetch(
      `https://us-central1-zionhills-f62b5.cloudfunctions.net/app/order`,
      // `http://localhost:5001/zionhills-f62b5/us-central1/app/order`,
      {
        method: "POST",
        body: JSON.stringify(store),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-API-KEY": "0e6f0324-ac86-4c7f-a2eb-59268ab0907f",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  };

  const verifyConfirm = async (store) => {
    return await fetch(
      `https://us-central1-zionhills-f62b5.cloudfunctions.net/app/verifyConfirm`,
      // `http://localhost:5001/zionhills-f62b5/us-central1/app/verifyConfirm`,
      {
        method: "POST",
        body: JSON.stringify(store),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-API-KEY": "0e6f0324-ac86-4c7f-a2eb-59268ab0907f",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleSubmit = async () => {
    setStore({
      ...store,
      errors: [],
      errorName: "",
    });
    if (!(await handleCheckAvailablity())) {
      return;
    }

    setStore({
      ...store,
      isDirty: false,
    });
    setIsSubmitting(true);
    const res = await createOrder(store);
    if (res.ok) {
      let { order } = await res.json();
      var options = {
        key: "rzp_live_KoXedhrIi9Wi7e", // Enter the Key ID generated from the Dashboard
        amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Zion Hills Golf County",
        description: store.bookingType || "Booking from zionhills.in",
        image: "https://zionhills-f62b5.web.app/static/media/logo.80a50dbb.svg",
        order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          // const confirm = {
          //   ok: false,
          // };
          const confirm = await verifyConfirm({
            ...store,
            orderId: order.id,
            orderDetails: {
              ...order,
            },
            paymentDetails: {
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              paymentSignature: response.razorpay_signature,
            },
          });

          if (confirm.ok) {
            const { bookingId, bookingNo, checkin, checkout, paymentDetails } =
              await confirm.json();
            setIsSubmitting(false);
            // alert("payment Verified", bookingId);
            navigate("/booking-confirmation", {
              state: {
                bookingId,
                bookingNo,
                checkin,
                checkout,
                paymentDetails,
              },
            });
          } else {
            setIsSubmitting(false);
            navigate("/booking-failed", {
              state: {
                failed: true,
              },
            });
            // setStore({
            //   checkin: set(new Date(), { hours: 15, minutes: 30, seconds: 0 }),
            //   checkout: addDays(
            //     set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
            //     1
            //   ),
            //   name: "",
            //   adults: 0,
            //   kids: 0,
            //   email: "",
            //   phone: "",
            //   nights: 1,
            //   totalIncGst: 0,
            //   totalGst: 0,
            //   total: 0,
            //   totalSC: 0,
            //   bookingType: "",
            //   mode: "online",
            // });
            // navigate("/");
          }
          //verify the payment and confirm the booking

          // navigate("/payment-sucess");
        },
        modal: { ondismiss: () => setIsSubmitting(false) },
        prefill: {
          name: store.name,
          email: store.email,
          contact: store.phone,
        },
        notes: {
          address: "Zion Hills Golf County, Kolar",
          name: store.name,
          email: store.email,
          contact: store.phone,
        },
        theme: {
          color: "#F15D26",
        },
      };
      const rzp = new Razorpay(options);
      setOrder(order);
      // alert(order);
      rzp.on("payment.failed", function (response) {
        setIsSubmitting(false);
        // console.log("payment failed");
      });
      rzp.on("ondismiss", () => {
        setIsSubmitting(false);
      });
      rzp.open();
    } else {
      alert("no response");
    }
  };

  const handleCancel = (bookingId, taskName) => {
    // console.log("Triggering Cancel");
    // if (!window.confirm("Are you sure, you wanna cancel?")) {
    //   return;
    // }

    setStore({
      ...store,
      isDirty: false,
    });
    toast.promise(
      async () => {
        try {
          const response = await fetch(
            `https://us-central1-zionhills-f62b5.cloudfunctions.net/app/cancelTempBooking`,
            // `http://localhost:5001/zionhills-f62b5/us-central1/app/cancelTempBooking`,
            {
              method: "POST",
              body: JSON.stringify({
                bookingId: store.bookingId ?? bookingId,
                taskName: store.taskDetails?.name ?? taskName,
              }),
              headers: {
                "Access-Control-Allow-Origin": "*",
                "X-API-KEY": "0e6f0324-ac86-4c7f-a2eb-59268ab0907f",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          if (response.ok) {
            setStore({
              checkin: set(new Date(), { hours: 15, minutes: 0, seconds: 0 }),
              checkout: addDays(
                set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
                1
              ),
              name: "",
              adults: 0,
              kids: 0,
              email: "",
              phone: "",
              nights: 1,
              totalIncGst: 0,
              totalGst: 0,
              total: 0,
              totalSC: 0,
              gst: 12,
              grandTotal: 0,
              bookingType: "",
              modeOfPayment: "online",
              isDirty: false,
            });
            navigate("/");
          }
        } catch (err) {
          // console.log("Error Canceling");
          setStore({
            checkin: set(new Date(), { hours: 15, minutes: 0, seconds: 0 }),
            checkout: addDays(
              set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
              1
            ),
            name: "",
            adults: 0,
            kids: 0,
            email: "",
            phone: "",
            nights: 1,
            totalIncGst: 0,
            totalGst: 0,
            total: 0,
            totalSC: 0,
            gst: 12,
            grandTotal: 0,
            bookingType: "",
            modeOfPayment: "online",
            isDirty: false,
          });
          navigate("/");
        }
      },
      {
        pending: "Canceling your selection",
        success: "Cancelled Successfully",
        error: "Error in selection, please retry",
      }
    );
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state

      // handleCancel();

      return <h3>Expired.. Redirecting</h3>;
    } else {
      // Render a countdown
      return (
        <h3>
          {minutes}:{seconds}
        </h3>
      );
    }
  };

  function usePrompt(message, when = true) {
    const { bookingId, taskDetails } = store;
    // console.log(bookingId, taskDetails);
    const blocker = useCallback(
      (tx) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(message)) {
          // console.log("cancelbooking");
          handleCancel(bookingId, taskDetails.name);
          tx.retry();
        }
      },
      [message, bookingId, taskDetails?.name]
    );

    useBlocker(blocker, when);
  }

  usePrompt("Leave screen?", store.isDirty);

  return (
    <div className="App">
      <Header />
      <div className="container">
        {store.errorName && store.errors.length !== 0 ? (
          <div
            style={{
              backgroundColor: "#FF0033",
              marginTop: 20,
              padding: 10,
            }}
          >
            <div>
              {store.errors.map((d, i) => {
                return (
                  <p key={i} style={{ color: "#fff" }}>
                    {d}
                  </p>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className="datepicker-zion">
          <div
            className="dflex"
            style={{
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="dflex" style={{ alignItems: "center" }}>
              <label className="label">Checkin</label>
              <div style={{ marginLeft: 10 }}>
                <p>{format(store.checkin, "dd/MM/yyyy hh:mm:ss a")}</p>
              </div>

              <label className="label" style={{ marginLeft: 20 }}>
                Checkout
              </label>
              <div style={{ marginLeft: 10 }}>
                <p>{format(store.checkout, "dd/MM/yyyy hh:mm:ss a")}</p>
              </div>
              {/* <div style={{ marginLeft: 20 }}>
                <button className="primary" onClick={fetchAvailableInventory}>
                  Check
                </button>
              </div> */}
            </div>
            <div>
              <p>
                <strong style={{ color: " #F15D26" }}>{store.nights}</strong>{" "}
                Night
              </p>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <PulseLoader color={color} loading={loading} size={20} />
          </div>
        ) : (
          <div className="bookingContainer" style={{ marginTop: "10px" }}>
            <div
              className="intractionBlock"
              style={{ display: isSubmitting ? "block" : "none" }}
            ></div>
            <p>
              {" "}
              Booking Type: <strong>{store.bookingType}</strong>
            </p>
            <form style={{ marginTop: 20 }}>
              <div className="dflex" style={{ flex: 1 }}>
                <div className="inputwrapper" style={{ width: "50%" }}>
                  <div className="dflex" style={{ alignItems: "center" }}>
                    <label>Name</label>
                    <input
                      className="bookingInput"
                      style={{ marginLeft: 10 }}
                      name="name"
                      value={store.name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div
                  className="inputwrapper"
                  style={{ width: "50%", marginLeft: 30 }}
                >
                  <div className="dflex" style={{ alignItems: "center" }}>
                    <label>Email</label>
                    <input
                      className="bookingInput"
                      style={{ marginLeft: 10 }}
                      name="email"
                      value={store.email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="dflex" style={{ flex: 1, marginTop: 20 }}>
                <div className="inputwrapper" style={{ width: "50%" }}>
                  <div className="dflex" style={{ alignItems: "center" }}>
                    <label>Phone</label>
                    <input
                      className="bookingInput"
                      style={{ marginLeft: 10 }}
                      name="phone"
                      value={store.phone}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div
                  className="dflex inputwrapper"
                  style={{ width: "50%", marginLeft: 30 }}
                >
                  <div className="dflex" style={{ alignItems: "center" }}>
                    <label>Adults</label>
                    <input
                      className="bookingInput"
                      style={{ marginLeft: 10 }}
                      name="adults"
                      value={store.adults}
                      disabled
                    />
                  </div>
                  <div
                    className="dflex"
                    style={{ marginLeft: 10, alignItems: "center" }}
                  >
                    <label>Kids</label>
                    <input
                      className="bookingInput"
                      style={{ marginLeft: 10 }}
                      name="kids"
                      value={store.kids}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="dflex">
              <div
                className="mobilefullwidth"
                style={{ width: "50%", marginTop: 50 }}
              >
                <p>This page will expire in</p>
                <strong>15 Minutes</strong>
                <div style={{ marginTop: 20 }}>
                  <Countdown
                    date={countdownRef.current + 900000}
                    precision={3}
                    renderer={renderer}
                    zeroPadTime={2}
                    onComplete={() => handleCancel()}
                  />
                </div>
              </div>

              <div
                className="dflex paymentsummary mobilefullwidth"
                style={{
                  marginTop: 50,
                  width: "50%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div
                  className="dflex mobilerowflex"
                  style={{ width: "80%", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: "#f1f1f1",
                      padding: "5px 10px",
                    }}
                  >
                    <p>Total</p>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <p style={{}}>{store.total}</p>
                  </div>
                </div>
                <div
                  className="dflex mobilerowflex"
                  style={{ width: "80%", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: "#f1f1f1",
                      padding: "5px 10px",
                    }}
                  >
                    <p>Service Charge(5%)</p>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <p style={{}}>{store.totalSC}</p>
                  </div>
                </div>
                <div
                  className="dflex mobilerowflex"
                  style={{ width: "80%", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: "#f1f1f1",
                      padding: "5px 10px",
                    }}
                  >
                    <p>GST({store.gst}%)</p>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <p style={{}}>{store.totalGst}</p>
                  </div>
                </div>
                <div
                  className="dflex mobilerowflex"
                  style={{ width: "80%", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: "#f1f1f1",
                      padding: "5px 10px",
                    }}
                  >
                    <p style={{ fontWeight: 700 }}>Grand Total</p>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <p style={{ fontWeight: 700, color: "#F15D26" }}>
                      {store.totalIncGst}
                    </p>
                  </div>
                </div>
                <div
                  className="dflex"
                  style={{ marginTop: 50, justifyContent: "flex-end" }}
                >
                  <button
                    disabled={isSubmitting || loading}
                    className="primary booknow"
                    style={{
                      color: "#000",
                      backgroundColor: "#fff",
                      padding: "10px 20px",
                      borderRadius: "0",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isSubmitting || loading}
                    className="primary booknow"
                    style={{
                      padding: "10px 20px",
                      borderRadius: "0",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    <div className="dflex" style={{ alignItems: "center" }}>
                      Pay Now
                      <div style={{ marginLeft: 5 }}>
                        <ClipLoader
                          color={"#fff"}
                          loading={isSubmitting}
                          size={15}
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ margin: "20px auto", textAlign: "center" }}>
        <p style={{ fontWeight: "bold" }}>
          Not finding what you are looking for? Contact us at{" "}
          <a href="tel:+919019231923">+91-9019231923</a>
        </p>
      </div>
    </div>
  );
}

export default BookingForm;
