import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import logo from "./logo.svg";
import { AppContext } from "./App";
import { addDays, format, set } from "date-fns";

function BookingFailed(props) {
  const { state } = useLocation();
  const { setStore } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    setStore({
      checkin: set(new Date(), { hours: 15, minutes: 0, seconds: 0 }),
      checkout: addDays(
        set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
        1
      ),
      name: "",
      adults: 0,
      kids: 0,
      email: "",
      phone: "",
      nights: 1,
      totalIncGst: 0,
      totalGst: 0,
      total: 0,
      totalSC: 0,
      bookingType: "",
      mode: "online",
    });
    if (!state?.failed) {
      navigate("/");
    }
  }, [state?.failed]);
  return (
    <div className="App">
      <header className="App-header">
        <div
          className="dflex"
          style={{
            maxWidth: 900,
            justifyContent: "flex-start",
            alignItems: "center",
            margin: "20px auto",
          }}
        >
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 1, margin: "0 auto", textAlign: "center" }}>
            <img src={logo} width="150" />
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
      </header>
      <div className="container">
        <div className="bookingContainer" style={{ textAlign: "center" }}>
          <div style={{ marginTop: "100px" }}>
            <h2>Your payment has failed</h2>{" "}
            <div style={{ marginTop: "10px" }}>
              Please retry again.
              <button
                style={{ marginLeft: "10px" }}
                className="primary"
                onClick={() => navigate("/")}
              >
                Retry
              </button>
            </div>
            <p style={{ marginTop: "40px" }}>
              Please refer to <a href="/terms-conditions">Our Policy</a>
            </p>
            <p style={{ marginTop: "5px" }}>
              For cancellation or change of dates, please contact
              <a href="tel:+919019231923">+91-9019231923</a>
            </p>
          </div>
        </div>
      </div>
      <div style={{ margin: "20px auto", textAlign: "center" }}>
        <p style={{ fontWeight: "bold" }}>
          Not finding what you are looking for? Contact us at{" "}
          <a href="tel:+919019231923">+91-9019231923</a>
        </p>
      </div>
    </div>
  );
}

export default BookingFailed;
