import React, { useContext, useEffect } from "react";
import logo from "./logo.svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import BackgroundSlider from "react-background-slider";
import DatePicker from "react-datepicker";
import { addDays, set, differenceInDays } from "date-fns";
import { AppContext } from "./App";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Header from "./Header";
function Contact(props) {
  return (
    <div>
      <Header />
      {/* <div className="divider"></div> */}
      <div
        style={{
          alignItems: "center",
          maxWidth: "80%",
          margin: "20px auto",
          color: "#555!important",
        }}
      >
        {/* <h2 className="heading">Zion Hills Golf County</h2>
        <p>Kolar</p> */}
      </div>

      <div
        style={{
          display: "flex",
          maxWidth: "80%",
          margin: "20px auto",
        }}
      >
        <div
          className="content"
          style={{ width: "65%", paddingRight: "30px", height: "100vh" }}
        >
          <h2 className="heading">Contact</h2>

          <p style={{ marginTop: "20px" }}>
            Zion Hills Golf County, Hanchala Gate, Bangarpet, Kolar, Karnataka
            563114
          </p>
          <p>Email : reservation@zionhills.in</p>
          <p> Phone : +91 9019231923</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
