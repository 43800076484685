import { isWeekend } from "date-fns";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./App";
import logo from "./logo.svg";
import man from "./man.svg";

function AvailableList({ available, priceList, startDate }) {
  const navigate = useNavigate();
  const { store, setStore } = useContext(AppContext);

  const handleBookNow = (priceData) => {
    if (
      Number(store.adults) > Number(priceData.adults) ||
      Number(store.kids) > Number(priceData.kids)
    ) {
      alert(
        `Pax Exceeded Cannot have more than ${priceData.adults} Adults & ${priceData.kids} Kids`
      );
      return;
    }

    //send Reservered Booking to a villa, //also sent book now click time, run delete code after 15 mins if payment is done dont delete
    // console.log("price", priceData);
    const price = isWeekend(store.checkin)
      ? Number(priceData.weekEndRate)
      : Number(priceData.weekDayRate);
    const total = price * Number(store.nights);
    const gstDecimal = (priceData.gst || 12) / 100;
    const totalSC = Number(total * 0.05); //sc
    const totalGst = Number(total * gstDecimal + totalSC * gstDecimal); //total gst
    const totalIncGst = Number(total + totalSC + totalGst); // grand total
    const grandTotal = totalIncGst;
    setStore({
      ...store,
      bookingType: priceData.title,
      particulars: [
        {
          id: priceData.id,
          discount: 0,
          price: price,
          title: priceData.title,
        },
      ],
      totalIncGst,
      totalGst,
      totalSC,
      total,
      gst: priceData.gst || 12,
      grandTotal,
    });
    navigate("/booking");
  };
  return (
    <div className="bookingContainer" style={{}}>
      {Object.keys(available).length !== 0 ? (
        Object.keys(available).map(function (key, i) {
          return (
            <div
              key={i}
              className="card"
              style={{
                border: "0.5px solid #bbbbbb",
                borderRadius: 20,
                overflow: "hidden",
              }}
            >
              <div className="dflex" style={{ width: "100%" }}>
                <div className="bookingImage" style={{ flex: 1 }}>
                  {priceList
                    .filter((d) => d.title === key)
                    .map((r, i) => (
                      <div
                        key={i}
                        style={{
                          height: "250px",
                          width: "100%",
                          maxWidth: "100%",
                          backgroundImage: `url(${r.image ?? logo})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    ))}

                  <span style={{ fontWeight: "bold" }}>
                    {available[key].length} units available
                  </span>
                </div>
                <div
                  className="bookingContent"
                  style={{
                    display: "flex",
                    padding: "20px",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <h1>{key}</h1>
                  {priceList
                    .filter((d) => d.title === key)
                    .map((r, i) => (
                      <div
                        key={i}
                        style={{
                          opacity:
                            Number(store.adults) > Number(r.adults) ||
                            Number(store.kids) > Number(r.kids)
                              ? 0.5
                              : 1,
                        }}
                      >
                        <h2>
                          ₹
                          {isWeekend(startDate) ? r.weekEndRate : r.weekDayRate}
                          {"*"}/
                          <span style={{ color: "#666", fontSize: "16px" }}>
                            night
                          </span>
                        </h2>
                        <p>GST & Service charge applicable</p>
                        <p style={{ fontSize: "14px" }}>{r.description}</p>
                        <p
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          <span
                            style={{ color: "#F15D26", fontWeight: "bold" }}
                          >
                            {available[key].length}
                          </span>{" "}
                          units available
                        </p>

                        <div
                          className="dflex paxicons"
                          style={{ marginTop: 20 }}
                        >
                          <span>
                            <img src={man} className="adultIcon" width="30" />{" "}
                            {r.adults}
                          </span>
                          <span style={{ marginLeft: 10 }}>
                            <img src={man} className="kidIcon" width="15" />{" "}
                            {r.kids}
                          </span>
                        </div>
                        <button
                          className="primary booknow"
                          style={{
                            alignSelf: "flex-end",
                            padding: "10px 20px",
                            borderRadius: "0",
                            fontSize: "20px",
                            fontWeight: "700",
                            backgroundColor:
                              Number(store.adults) > Number(r.adults) ||
                              Number(store.kids) > Number(r.kids)
                                ? "#333"
                                : "#F15D26",
                          }}
                          onClick={() => handleBookNow(r)}
                        >
                          Book Now
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div style={{ marginTop: "100px", textAlign: "center" }}>
          <h2>No availablity for selected dates</h2>{" "}
        </div>
      )}
    </div>
  );
}

export default AvailableList;
