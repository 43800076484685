import { createContext, useEffect, useState } from "react";
import "./App.css";

import { Link, useRoutes } from "react-router-dom";
import Dashboard from "./DashBoard";
import BookingForm from "./BookingForm";
import { addDays, set } from "date-fns";
import BookingConfimation from "./BookingConfimation";
import BookingFailed from "./BookingFailed";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Home";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import RefundCancellation from "./RefundCancellation";
import Contact from "./Contact";
import Menu from "./Menu";

export const AppContext = createContext({
  store: {
    checkin: set(new Date(), { hours: 15, minutes: 0, seconds: 0 }),
    checkout: addDays(
      set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
      1
    ),
    name: "",
    adults: 0,
    kids: 0,
    email: "",
    phone: "",
    nights: 1,
    totalIncGst: 0,
    totalGst: 0,
    total: 0,
    totalSC: 0,
    bookingType: "",
    modeOfPayment: "online",
    bookingId: "",
  },
  setStore: () => {},
});

export function App() {
  const [store, setStore] = useState({
    checkin: set(new Date(), { hours: 15, minutes: 0, seconds: 0 }),
    checkout: addDays(
      set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
      1
    ),
    name: "",
    adults: 0,
    kids: 0,
    email: "",
    phone: "",
    nights: 1,
    totalIncGst: 0,
    totalGst: 0,
    total: 0,
    totalSC: 0,
    bookingType: "",
    modeOfPayment: "online",
    isDirty: false,
    bookingId: "",
  });

  const element = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/availablity",
      element: <Dashboard />,
    },
    {
      path: "/booking",
      element: <BookingForm />,
    },
    {
      path: "/booking-confirmation",
      element: <BookingConfimation />,
    },
    {
      path: "/booking-failed",
      element: <BookingFailed />,
    },
    {
      path: "/terms-conditions",
      element: <TermsConditions />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/refund-cancellation",
      element: <RefundCancellation />,
    },
    {
      path: "/contact-us",
      element: <Contact />,
    },
    {
      path: "/menu",
      element: <Menu />,
    },
  ]);
  return (
    <AppContext.Provider value={{ store, setStore }}>
      {element}
      <div
        className="dflex"
        style={{
          width: "100%",
          height: "50px",
          padding: 10,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f7f7f7",
        }}
      >
        <div>
          <Link to="/terms-conditions">Terms & Conditions</Link>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Link to="refund-cancellation">Refund / Cancellation</Link>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Link to="contact-us">Contact</Link>
        </div>
      </div>
      <ToastContainer />
    </AppContext.Provider>
  );
}

export default App;
