import React, { useContext, useEffect } from "react";
import logo from "./logo.svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import BackgroundSlider from "react-background-slider";
import DatePicker from "react-datepicker";
import { addDays, set, differenceInDays } from "date-fns";
import { AppContext } from "./App";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Header from "./Header";

function RefundCancellation(props) {
  return (
    <div>
      <Header />
      {/* <div className="divider"></div> */}
      <div
        style={{
          alignItems: "center",
          maxWidth: "80%",
          margin: "20px auto",
          color: "#555!important",
        }}
      >
        {/* <h2 className="heading">Zion Hills Golf County</h2>
        <p>Kolar</p> */}
      </div>

      <div
        style={{
          display: "flex",
          maxWidth: "80%",
          margin: "20px auto",
        }}
      >
        <div
          className="content"
          style={{ width: "65%", paddingRight: "30px", height: "100vh" }}
        >
          <h2 className="heading">Cancellation & Refund Policy</h2>

          <ul style={{ marginTop: "20px" }}>
            <li>
              Confirmed reservation may be altered / cancelled only vide
              intimation by email to&nbsp;
              <a href="mailto:reservation@zionhills.in">
                reservation@zionhills.in
              </a>{" "}
              or call to 96866 58936 reaches Zion Hills. Cancellation charges
              apply as follows:
              <ul>
                <li>Upto 48 prior to check in date: Nil</li>
                <li>24 to 48 hours prior to check in date: 20%&nbsp;</li>
                <li>No show: 100%</li>
              </ul>
            </li>
            <li>
              Guests have the option of rescheduling their booking to any date 3
              months from the booking date and the same is subject to
              availability, mutually convenient to guest and Zion Hills. (Note:
              If original booking was made on a weekday, then rescheduling will
              be done only on weekday / non holiday.)
            </li>
            <li>Refund is subject to applicable cancellation charges&nbsp;</li>
            <li>
              Refund will be paid through NEFT in the name of the person who
              made the booking and payment within 15 days if cancellation.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RefundCancellation;
