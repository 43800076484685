import React, { useContext, useEffect } from "react";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import BackgroundSlider from "react-background-slider";
import DatePicker from "react-datepicker";
import { addDays, set, differenceInDays } from "date-fns";
import { AppContext } from "./App";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Header from "./Header";

function Home(props) {
  const { store, setStore } = useContext(AppContext);
  let schema = Yup.object().shape({
    checkin: Yup.date()
      .default(() => set(new Date(), { hours: 15, minutes: 0, seconds: 0 }))
      .required(),
    checkout: Yup.date()
      .default(() =>
        set(addDays(new Date(), 1), { hours: 12, minutes: 0, seconds: 0 })
      )
      .required(),
    adults: Yup.number()
      .integer()
      .min(1)
      .typeError("Adults: Enter a valid number")
      .required(),
    kids: Yup.number()
      .integer()
      .min(0)
      .typeError("Kids: Enter a valid number")
      .required(),
  });

  const images = [
    {
      url: "https://zionhills.in/co18/wp-content/uploads/2022/06/Zion-overview-scaled.jpg",
    },
    {
      url: "https://zionhills.in/co18/wp-content/uploads/2022/06/Zion-lake-5-May-2022.jpg",
    },
    {
      url: "https://zionhills.in/co18/wp-content/uploads/2019/02/1-10b-sunset.jpeg",
    },
    {
      url: "https://zionhills.in/co18/wp-content/uploads/2019/02/1-8-hole-7.jpg",
    },
    {
      url: "https://zionhills.in/co18/wp-content/uploads/2019/01/img_0429.jpg",
    },
    // {
    //   url: "https://zionhills.in/co18/wp-content/uploads/2019/02/5-Hole-6.jpg",
    // },
  ];
  const handleStartDateChange = (date) => {
    let startDate = set(date, { hours: 15, minutes: 0, seconds: 0 });
    // setStartDate(startDate);
    // setEndDate(addDays(startDate, 1));
    // setNights(1);
    setStore({
      ...store,
      checkin: startDate,
      checkout: set(addDays(date, 1), { hours: 12, minutes: 0, seconds: 0 }),
      nights: 1,
    });
  };

  const handleEndDateChange = (date) => {
    let endDate = set(date, { hours: 12, minutes: 0, seconds: 0 });

    setStore({
      ...store,
      checkout: endDate,
      nights: differenceInDays(endDate, store.checkin) + 1,
    });
  };

  const handleOnChange = (e) => {
    setStore({
      ...store,
      errorName: "",
      errors: [],
      [e.target.name]: e.target.value,
    });
  };

  const naviagte = useNavigate();

  const handleCheckAvailablity = () => {
    schema
      .validate({
        checkin: store.checkin,
        checkout: store.checkout,
        adults: store.adults,
        kids: store.kids,
      })
      .then(function (valid) {
        if (valid) {
          setStore({
            ...store,
            errorName: "",
            errors: [],
          });
          naviagte("/availablity");
        } // => true
      })
      .catch((err) => {
        console.log(err);
        setStore({
          ...store,
          errorName: err.name,
          errors: err.errors,
        });
      });
  };

  useEffect(() => {
    setStore({
      ...store,
      checkin: set(new Date(), { hours: 15, minutes: 0, seconds: 0 }),
      checkout: addDays(
        set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
        1
      ),
      adults: 0,
      kids: 0,
      nights: 1,
      totalIncGst: 0,
      totalGst: 0,
      total: 0,
      totalSC: 0,
      gst: 12,
      bookingType: "",
      mode: "online",
      isDirty: false,
      bookingId: "",
    });
  }, []);

  return (
    <div>
      {/* <div className="divider"></div> */}
      <Header />
      <div
        style={{
          alignItems: "center",
          maxWidth: "80%",
          margin: "20px auto",
          color: "#555!important",
        }}
      >
        {/* <h2 className="heading">Zion Hills Golf County</h2>
        <p>Kolar</p> */}
      </div>
      <div
        className="backgroundImages"
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "80%",
          margin: "20px auto",
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <div className="dflex" style={{ width: "50%" }}>
          <div
            style={{
              width: "100%",
              height: "420px",
              backgroundImage: `url(${images[0].url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
        <div
          className="dflex"
          style={{
            width: "50%",
            height: "400px",

            flexDirection: "column",
          }}
        >
          <div
            className="dflex"
            style={{ marginBottom: 10, marginTop: "-10px" }}
          >
            <div
              className="dflex"
              style={{
                width: "100%",
                height: "210px",
                backgroundImage: `url(${images[1].url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginLeft: 10,
              }}
            ></div>
            <div
              style={{
                width: "100%",
                height: "210px",
                backgroundImage: `url(${images[2].url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

                marginLeft: 10,
              }}
            ></div>
          </div>
          <div className="dflex">
            <div
              style={{
                width: "100%",
                height: "210px",
                backgroundImage: `url(${images[3].url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

                marginLeft: 10,
              }}
            ></div>
            <div
              style={{
                width: "100%",
                height: "210px",
                backgroundImage: `url(${images[4].url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

                marginLeft: 10,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="mainPage"
        style={{
          display: "flex",
          maxWidth: "80%",
          margin: "20px auto",
        }}
      >
        <div className="content" style={{ width: "55%", paddingRight: "30px" }}>
          <h2 className="heading">The Perfect Getaway</h2>
          <p style={{ marginTop: "20px" }}>
            Located just outside Bengaluru at Kolar, Zion Hills Golf County is
            the perfect getaway destination, whether it be for a corporate
            huddle or a family celebration or a personal vacation. There’s so
            much to discover at Zion Hills – starting with oneself, of course.
            Soak in the sunshine, breathe in the fresh air and relish the great
            outdoors, play or learn golf, enjoy a meal at the iconic club house
            that offers the perfect setting to hang out and commune at or to
            simply gaze into starry oblivion. Vast golfing greens, endless skies
            and infinite tranquillity ensconce your exquisite and inviting
            holiday home.
          </p>
          {/* <p style={{ marginTop: "10px" }}>
            {" "}
            Zion Hills Golf County is a resort like villa community, located
            around a golf course. It defines great living, melding a passion for
            the outdoors with a relaxing lifestyle. Vast golfing greens, endless
            skies and infinite tranquility ensconce your exquisite and inviting
            holiday home.
          </p> */}
          <div style={{ marginTop: "20px" }}>
            <div style={{ marginTop: "20px" }}>
              <h2 className="heading">Amenities</h2>

              <div style={{ marginTop: "20px" }}>
                <ul>
                  <li>Golf Course</li>
                  <li>
                    <Link to="/menu">Multi cuisine restaurant</Link>
                  </li>

                  <li>Outdoor sports (Basket Ball, Tennis, Pickle Ball)</li>
                  <li>Swimming pool &amp; indoor sports (extra charge)</li>
                </ul>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h2 className="heading">Amenities in villas</h2>

            <div style={{ marginTop: "20px" }}>
              <ul>
                <li>Tv</li>
                <li>Fridge </li>
                <li>Microwave </li>
                <li>Induction/gas</li>
                <li>Crockery/cutlery/ basic cookware </li>
                <li>Tea coffee </li>
                <li>Linen</li>
                <li>Toiletries </li>
              </ul>
              <p>
                Please note that wifi is not yet available. Kindly bring your
                SIM router/ dongle (Jio &amp; Airtel connectivity is best)
              </p>
            </div>
          </div>
        </div>

        <div className="bookingBox" style={{ width: "45%" }}>
          <div
            className="container"
            style={{
              position: "sticky",
              top: "50px",
              width: "100%",
              minHeight: "400px",
              borderRadius: "20px",
              padding: "30px",
            }}
          >
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                From ₹ 6,000
              </span>{" "}
              + Taxes / room night
            </p>
            <div
              className="dflex"
              style={{
                width: "100%",
                marginTop: 50,
                gap: "10px",
                // border: "1px solid #999",
                // borderTopLeftRadius: 10,
                // borderTopRightRadius: 10,
              }}
            >
              <div
                className="dflex"
                style={{ flex: 1, flexDirection: "column" }}
              >
                <label className="label">Checkin</label>
                <div className="datepicker">
                  <DatePicker
                    selected={store.checkin}
                    minDate={new Date()}
                    onChange={(date) => handleStartDateChange(date)}
                    selectsStart
                    startDate={store.checkin}
                    dateFormat="dd/MM/yyyy"
                    endDate={store.endDate}
                  />
                </div>
              </div>

              <div
                className="dflex"
                style={{ flex: 1, flexDirection: "column" }}
              >
                <label className="label">Checkout</label>
                <div className="datepicker">
                  <DatePicker
                    selected={store.checkout}
                    onChange={(date) => handleEndDateChange(date)}
                    selectsEnd
                    startDate={store.checkin}
                    endDate={store.checkout}
                    dateFormat="dd/MM/yyyy"
                    minDate={addDays(store.checkin, 1)}
                  />
                </div>
                {/* <div style={{ marginLeft: 20 }}>
                  <button className="primary">Check</button>
                </div> */}
              </div>
            </div>
            <div className="dflex" style={{ gap: "10px" }}>
              <div
                className="dflex"
                style={{ marginTop: 20, flex: 1, flexDirection: "column" }}
              >
                <label className="label">Adults & Kids (> 10 years)</label>
                <input
                  name="adults"
                  onChange={handleOnChange}
                  value={Number(store.adults)}
                  className="home-input"
                  type="number"
                />
              </div>
              <div
                className="dflex"
                style={{ marginTop: 20, flex: 1, flexDirection: "column" }}
              >
                <label className="label">Kids (5-10 years)</label>
                <input
                  name="kids"
                  onChange={handleOnChange}
                  value={Number(store.kids)}
                  className="home-input"
                  type="number"
                />
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <button
                className="primary"
                style={{ padding: 10 }}
                onClick={handleCheckAvailablity}
              >
                Check Availability
              </button>
            </div>
            {store.errorName && store.errors ? (
              <div
                style={{
                  backgroundColor: "#FF0033",
                  marginTop: 20,
                  padding: 10,
                }}
              >
                <div>
                  {store.errors.map((d, i) => {
                    return (
                      <p key={i} style={{ color: "#fff" }}>
                        {d}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
