import React, { useContext, useEffect } from "react";
import logo from "./logo.svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import BackgroundSlider from "react-background-slider";
import DatePicker from "react-datepicker";
import { addDays, set, differenceInDays } from "date-fns";
import { AppContext } from "./App";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Link } from "react-router-dom";
import Header from "./Header";
function TermsConditions(props) {
  return (
    <div>
      <Header />
      {/* <div className="divider"></div> */}
      <div
        style={{
          alignItems: "center",
          maxWidth: "80%",
          margin: "20px auto",
          color: "#555!important",
        }}
      >
        {/* <h2 className="heading">Zion Hills Golf County</h2>
        <p>Kolar</p> */}
      </div>

      <div
        style={{
          display: "flex",
          maxWidth: "80%",
          margin: "20px auto",
        }}
      >
        <div className="content" style={{ width: "65%", paddingRight: "30px" }}>
          <h2 className="heading">Terms & Conditions</h2>
          <p style={{ marginTop: "20px" }}>
            <strong>
              This &ldquo;Terms &amp; Conditions&quot; agreement for
              https://booking.zionhills.in, is in effect as of Sept 1, 2022.
            </strong>
          </p>
          <p style={{ marginTop: "10px" }}>
            <strong>Reservation Policy</strong>
          </p>
          <ul>
            <li>
              Reservation will be confirmed only after 100% advance payment.
            </li>
            <li>
              Advance payment can be made using any of the following modes:
              Credit Card/NEFT/UPI payment to our official bank account.
            </li>
            <li>Confirmed bookings are not transferable</li>
          </ul>
          <p style={{ marginTop: "10px" }}>
            <strong>Cancellation &amp; Refund Policy</strong>
          </p>
          <ul>
            <li>
              Confirmed reservation may be altered / cancelled only vide
              intimation by email to{" "}
              <a href="mailto:reservations@zionhills.in">
                reservations@zionhills.in
              </a>{" "}
              or call to 96866 58936. Cancellation charges apply as follows:
              <ul>
                <li>Upto 48 prior to check in date: Nil</li>
                <li>24 to 48 hours prior to check in date: 20%&nbsp;</li>
                <li>No show: 100%</li>
              </ul>
            </li>
            <li>
              Guests have the option of rescheduling their booking to any date 3
              months from the booking date and the same is subject to
              availability, mutually convenience guest and Zion Hills. (Note: If
              original booking was made on a weekday, then rescheduling will be
              done only on weekday / non holiday.)
            </li>
            <li>Refund is subject to applicable cancellation charges&nbsp;</li>
            <li>
              Refund will be paid through NEFT in the name of the person who
              made the booking and payment within 15 days if cancellation.
            </li>
          </ul>
          <p style={{ marginTop: "10px" }}>
            <strong>Responsible Use and Conduct</strong>
          </p>
          <ul>
            <li>
              We required information (such as identification, contact details,
              etc.) as part of the registration process, or as part of your
              ability to access the premises of Zion Hills. Please ensure that
              any information you provide will always be accurate, correct, and
              up-to date.
            </li>
            <li>
              Engaging in any activity that disrupts or interferes with our
              premises, is strictly prohibited. Please read the booking emails
              sent to you with regard to guidelines for stay at Zion Hills.
            </li>
            <li>
              You are solely responsible for any consequences, losses, or
              damages that we may directly or indirectly incur or suffer due to
              any unauthorized activities conducted by you.
            </li>
            <li>
              It is illegal to threaten, defame, abuse, harass, degrade,
              intimidate or use explicit language;
            </li>
            <li>
              Loud music cannot be played after 10:00 pm on week days and 11.00
              pm on weekends
            </li>
            <li>
              We reserve right to take necessary action including eviction /
              reporting to law enforcement authorities in the event of any
              misconduct during your stay which breaches the terms and
              conditions of Zion Hills.
            </li>
            <li>
              Zion Hills will not be liable for any direct, indirect,
              incidental, consequential or exemplary loss or damages which may
              be incurred by you as a result of using our resources.
            </li>
          </ul>
          <p style={{ marginTop: "10px" }}>
            <strong>Check in/out Time</strong>
          </p>
          <p>Check-in 3 PM and Check-out 12 PM</p>
          <p style={{ marginTop: "10px" }}>
            <strong>Identity Proof</strong>
          </p>
          <p>
            {" "}
            Guests are requested to bring along with them any one of the
            following
          </p>
          <p>
            Identity Proofs Indian national : Aadhar Card / Driver's License /
            Passport
          </p>
          <p>Foreign national : Aadhar Card / Passport</p>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
